.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.list {
  margin-top: 20px;
  padding-left: 20px;
  list-style: disc outside;
  margin: 0;

  li {
    color: var(--color-gray);

    .normalcase {
      text-transform: none;
      font-weight: var(--width-regular);
    }
  }
}
