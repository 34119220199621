.border {
  @media (min-width: 992px) {
    margin: 40px 0;

    border-top: 1px solid var(--color-white);
    border-bottom: 1px solid var(--color-white);
  }
}

.wrapper {
  @media screen and (max-width: 992px) {
    padding: 0 !important;
    min-width: 100px !important;
  }
}

.footer {
  display: flex;
  flex-direction: column;

  padding-top: 20px;

  border-top: 1px solid var(--color-gray);

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: center;

    padding: 21px 0;

    border: none;
  }
}

.link {
  display: flex;
  align-items: center;

  margin: 6px 0 6px 15px;

  font-size: 10px;
  font-weight: var(--width-bold);
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-color: var(--color-white);

  @media (min-width: 992px) {
    font-size: var(--size-medium);

    margin: 0 36px;

    text-decoration: none;
  }

  @media (min-width: 1200px) {
    font-size: var(--size-xBig);
  }
}

.image {
  width: 8px;
  height: 8px;

  @media (min-width: 992px) {
    width: 28px;
    height: 28px;
  }
}
