.white {
  color: var(--color-white);
}

.black {
  color: var(--color-black);
}

.black {
  color: var(--color-black);
}

.gray {
  color: var(--color-gray);
}

.xLarge {
  font-size: var(--size-xLarge);

  text-transform: uppercase;
  font-weight: var(--width-bold);
  line-height: normal;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    font-size: var(--size-mLarge);
  }

  @media screen and (max-width: 768px) {
    font-size: var(--size-large);
  }
}

.mLarge {
  font-size: var(--size-mLarge);

  text-transform: uppercase;
  font-weight: var(--width-bold);
  line-height: normal;
  z-index: 1;

  @media screen and (max-width: 768px) {
    font-size: var(--size-large);
  }

  @media screen and (max-width: 576px) {
    font-size: var(--size-big);
  }
}

.large {
  font-size: var(--size-large);
  font-weight: var(--width-bold);
  line-height: 120%;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    line-height: normal;
    font-size: var(--size-big);
  }

  @media screen and (max-width: 576px) {
    line-height: normal;
    font-size: var(--size-normal);
  }
}

.xBig {
  font-size: var(--size-xBig);
  text-transform: uppercase;
  line-height: normal;

  font-weight: var(--width-bold);
  font-style: normal;
  text-decoration-color: var(--color-gray);

  @media screen and (max-width: 576px) {
    font-size: var(--size-medium);
  }
}

.medium {
  font-size: var(--size-medium);
  text-transform: uppercase;

  font-weight: var(--width-bold);
  line-height: normal;

  font-style: normal;
  text-decoration-color: var(--color-gray);

  @media screen and (max-width: 768px) {
    font-size: var(--size-small);
  }
}

.text {
  font-size: var(--size-text);
  font-weight: var(--width-medium);
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: var(--size-small);
    line-height: normal;
  }
}

.grayOutline {
  text-shadow: -1px -1px 0 var(--color-dark-gray), 1px -1px 0 var(--color-dark-gray),
    -1px 1px 0 var(--color-dark-gray), 1px 1px 0 var(--color-dark-gray);
  color: var(--color-black);
}

.whiteOutline {
  text-shadow: -1px -1px 0 var(--color-white), 1px -1px 0 var(--color-white),
    -1px 1px 0 var(--color-white), 1px 1px 0 var(--color-white);
  color: var(--color-black);
}

.wbold {
  font-weight: var(--width-bold);
}

.wmedium {
  font-weight: var(--width-bold);
}

.wregular {
  font-weight: var(--width-regular);
}
