.info {
  position: sticky;
  top: 0;
  border: 2px solid var(--color-white);
  width: 35%;
  padding: 20px;
  box-sizing: border-box;
  align-self: flex-start;

  @media screen and (max-width: 992px) {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .block {
    width: 100%;
    margin-bottom: 100px;
    margin-top: 40px;

    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
      margin-top: 20px;
    }

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      @media screen and (max-width: 992px) {
        margin-bottom: 6px;
      }

      .normalcase {
        text-transform: none;
        font-weight: var(--width-regular);
      }
    }
  }
}

.icon {
  width: 26px;
  height: 26px;

  @media screen and (max-width: 992px) {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
}

.text {
  font-size: var(--size-medium);
  font-weight: var(--width-regular);
  text-transform: none;

  @media screen and (max-width: 1200px) {
    font-size: var(--size-normal);
  }
}

.title {
  font-size: var(--size-xBig);

  @media screen and (max-width: 1200px) {
    font-size: var(--size-big);
  }
}
