.wrapper {
  display: flex;
  flex-direction: column;

  position: relative;
}

.options {
  position: absolute;
  top: 88%;
  left: 0;
  right: 0;
  z-index: 9999;
  max-height: 170px;

  display: flex;
  flex-direction: column;

  border-radius: 0 0 10px 10px;
  border: 2px solid var(--color-white);
  box-sizing: border-box;

  background-color: var(--color-black);

  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.selectBlocker {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
