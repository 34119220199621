.cart {
  width: 100%;
  height: 100%;
}

.wrapper {
  cursor: pointer;
  position: relative;

  margin-top: -4px;

  width: 20px;
  height: 18px;

  @media (min-width: 992px) {
    margin-top: -6px;

    width: 30px;
    height: 27px;
  }

  @media (min-width: 1200px) {
    margin-top: -8px;

    width: 40px;
    height: 36px;
  }
}

.indicator {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 2px;

  right: 0;
  left: 0;

  margin: auto;

  font-size: 10px;
  font-weight: var(--width-medium);
  color: var(--color-white);

  @media (min-width: 992px) {
    bottom: 4px;
    font-size: var(--size-small);
  }

  @media (min-width: 1200px) {
    bottom: 8px;
  }
}
