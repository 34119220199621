.container {
  margin-bottom: 60px;
}

.wrapper {
  display: flex;
  gap: 65px;
  margin: 40px 0;

  @media screen and (max-width: 992px) {
    gap: 20px;
    flex-direction: column;
    margin: 20px 0;
  }
}

.textSection {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.imgSection {
  max-width: 450px;
  display: flex;
  @media screen and (max-width: 992px) {
    max-width: none;
    justify-content: center;
    width: 100%;
  }
}

.priceWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 46px 0;

  @media screen and (max-width: 992px) {
    margin: 32px 0;
  }
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.list {
  padding-left: 20px;
  list-style: disc outside;
  margin: 0;
  color: var(--color-dark-gray);

  li {
    margin-bottom: 18px;
  }

  p {
    text-transform: none;
    color: var(--color-dark-gray);
  }
}

.fullscreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.category {
  margin-top: 12px;
}
