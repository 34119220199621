.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.videoWrapper {
  border: 2px solid var(--color-light-gray-alt);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;

  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
