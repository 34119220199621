.wrapper {
  display: flex;
  position: relative;
  margin-bottom: 400px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 300px;
  }

  @media screen and (max-width: 992px) {
    margin-bottom: 0;
  }
}

.textSection {
  margin-top: 100px;
  width: 70%;
  padding: 25px 60px;
  box-sizing: border-box;
  border: 6px solid var(--color-gray);
  border-radius: 20px;
  position: relative;
  align-self: flex-start;

  @media screen and (max-width: 1200px) {
    padding: 16px 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px 16px;
    margin-top: 40px;
    border: 3px solid var(--color-gray);
  }
}

.text {
  font-style: italic;
  text-transform: none;

  @media screen and (max-width: 768px) {
    font-size: var(--size-normal);
  }

  @media screen and (max-width: 576px) {
    font-size: var(--size-small);
  }

  @media screen and (max-width: 576px) {
    line-height: 100%;
    font-weight: var(--width-normal);
  }
}

.author {
  text-transform: none;

  position: absolute;
  bottom: -16px;
  right: 80px;
  line-height: 30px;

  background-color: var(--color-black);
  padding: 0 16px;

  @media screen and (max-width: 1200px) {
    right: 100px;
  }

  @media screen and (max-width: 576px) {
    right: 50px;
  }
}

.authorImageWrapper {
  width: 45%;
  position: absolute;
  right: 0;
  top: 0;
}

.authorImage {
  object-fit: contain;
}
