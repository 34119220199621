.wrapper {
  position: relative;
  display: flex;
  gap: 20px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 0;
  }
}

.itemsWrapper {
  flex: 1;

  @media screen and (max-width: 992px) {
    flex: auto;
    width: 100%;
  }
}

.title {
  border: 2px solid var(--color-white);
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 992px) {
    border: none;
    padding: 10px 0;
  }
}

.scroll {
  width: 100%;
}

.fullscreenContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}
