.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 10px 8px 18px;

  border-bottom: 1px solid var(--color-gray);
}

.drawer {
  background-color: var(--color-mobile-menu) !important;
  max-width: 200px;
}
