.image {
  width: 450px !important;
  height: 550px !important;
  border-radius: 10px;
  object-fit: cover;
  img {
    border-radius: 10px;
  }
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 296px !important;
    height: 400px !important;
  }
}

.thumbnail {
  border: none !important;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  img {
    height: 170px !important;
    width: 160px !important;
    object-fit: cover;
    object-position: top;
  }

  height: 150px !important;
  width: 135px !important;
}
