.mb {
  margin-bottom: 120px;

  @media screen and (max-width: 992px) {
    margin-bottom: 50px;
  }
}

.bg {
  width: 100%;

  background-image: url('/assets/images/abstract-bg.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 572px;

  @media screen and (max-width: 1200px) {
    background-size: 515px;
  }

  @media screen and (max-width: 768px) {
    background-size: 455px;
  }

  @media screen and (max-width: 576px) {
    background: none;
  }
}

.section {
  width: 50%;

  margin-bottom: 40px;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.radioWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 30px 60px 40px 0;
}

.inputsWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 40px;

  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
}

.uppercase {
  text-transform: uppercase;
}
