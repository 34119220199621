.container {
  margin: 0 auto;
  max-width: 1240px;
  min-width: 335px;
  padding: 0 18px;

  @media (min-width: 992px) {
    padding: 0 100px;
  }
}
