.border {
  @media (min-width: 992px) {
    margin: 40px 0;

    border-top: 1px solid var(--color-white);
    border-bottom: 1px solid var(--color-white);
  }
}

.wrapper {
  padding: 14px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 992px) {
    padding: 5px 0;
  }
}

.drawer {
  display: block;

  @media (min-width: 992px) {
    display: none;
  }
}

.logo {
  display: none;

  @media (min-width: 992px) {
    display: block;

    height: 60px;
    width: 56px;
  }

  @media (min-width: 1200px) {
    height: 90px;
    width: 80px;
  }
}

.navigation {
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.languages {
  margin-right: 16px;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-gap: 6px;

  @media (min-width: 992px) {
    margin-right: 36px;
  }

  @media (min-width: 1200px) {
    margin-right: 48px;
  }
}

.flag {
  width: 20px;
  height: 14px;

  @media (min-width: 992px) {
    width: 30px;
    height: 21px;
  }

  @media (min-width: 1200px) {
    width: 40px;
    height: 28px;
  }
}

.opacity {
  opacity: 0.5;
}
