.wrapper {
  width: 100%;
  padding: 0 200px;
  margin: 50px 0;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 992px) {
    padding: 0 100px;
    margin-bottom: 80px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 18px;
    margin-bottom: 50px;
  }

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.radioWrap {
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
  margin-bottom: 16px;
}

.info {
  margin-bottom: 50px;
}
