@import url('styles/reset.scss');
@import url('styles/variables.scss');

body {
  font-family: 'Inter', sans-serif;
  color: var(--color-white);
  font-weight: var(--width-medium);

  background-color: var(--color-black);
  overflow-y: scroll;
}
