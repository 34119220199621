.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 16px;
  }
}

.image {
  width: 150px;
  object-fit: contain;
}

.table {
  th,
  td {
    padding: 15px;
  }
  tr {
    td {
      color: var(--color-red);
    }
    &:nth-child(2) {
      td {
        color: var(--color-yellow);
      }
    }
  }

  @media screen and (max-width: 768px) {
    th,
    td {
      padding: 8px;
    }
  }

  @media screen and (max-width: 576px) {
    th,
    td {
      padding: 5px;
    }
  }
}
