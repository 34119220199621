.toast {
  background: var(--color-black) !important;
  color: var(--color-white) !important;
  font-size: var(--size-normal);
  font-weight: var(--width-regular);
  border-radius: 0 !important;
  padding: 10px 20px !important;
  margin-top: 2px;
  border: 2px solid var(--color-white);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
}
