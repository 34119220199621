.wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.imageSection {
  margin-left: 20px;
  width: 60%;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin: 0;
  }
}

.imageWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
}

.gridFix {
  display: grid;
}

.image {
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 400px;

  @media screen and (max-width: 1200px) {
    height: 500px;
  }

  @media screen and (max-width: 992px) {
    height: 400px;
  }

  @media screen and (max-width: 576px) {
    height: 250px;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 40%;

  @media screen and (max-width: 1200px) {
    width: 100%;
    gap: 10px;
    margin-bottom: 16px;
  }
}

.absoluteLogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 30%;
  object-fit: contain;
}
