.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40%;
  gap: 20px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
