.markup {
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    justify-content: space-between;
  }
}

.footer {
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
}
