.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray);
}

.icon {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  padding: 0 8px;
  background-color: var(--color-black);
  object-fit: contain;

  @media screen and (max-width: 576px) {
    width: 24px;
    height: 24px;
    padding: 0 4px;
  }
}

.main {
  height: 100px !important;

  @media screen and (max-width: 992px) {
    height: 82px !important;
  }

  @media screen and (max-width: 576px) {
    height: 64px !important;
  }
}
