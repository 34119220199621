.wrapper {
  display: flex;

  gap: 6px;
}

.button {
  width: 60px;
  height: 60px;

  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--size-large);

  padding: 0;

  border: 2px solid var(--color-white);
  cursor: pointer;

  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-white);
    color: var(--color-black);
  }

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: var(--size-medium);
  }

  @media screen and (max-width: 576px) {
    width: 30px;
    height: 30px;
    font-size: var(--size-text);
  }
}

.text {
  color: var(--color-white);
  font-size: var(--size-large);
  font-weight: var(--wifth-regular);

  @media screen and (max-width: 768px) {
    font-size: var(--size-medium);
  }

  @media screen and (max-width: 576px) {
    font-size: var(--size-text);
  }
}

.textWrap {
  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 576px) {
    width: 30px;
    height: 30px;
  }
}
