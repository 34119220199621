.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;

  margin: 40px 0;

  @media screen and (max-width: 768px) {
    > :last-child {
      display: none;
    }
    margin: 20px 0;
  }
}

.loaderWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
