.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 10px;

  font-size: var(--size-normal);
  font-weight: var(--width-medium);
  color: var(--color-gray);

  @media screen and (max-width: 576px) {
    font-size: var(--size-small);
  }
}

.errorLabel {
  color: var(--color-red);
}

.addonAfter {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  height: 100%;
}

.inputWrapper {
  display: flex;
  position: relative;

  border-radius: 10px;
  border: 2px solid var(--color-white);
  overflow: hidden;
}

.input {
  flex: 1;
  outline: none;
  border: none;
  padding: 5px 10px;
  display: flex;

  background: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--size-medium);
  font-weight: var(--width-regular);
  transition: all 0.2s ease-in-out;
  color: var(--color-white);
  margin: 0;

  &::placeholder {
    font-size: var(--size-medium);
    font-weight: var(--width-regular);
    color: var(--color-gray);
    text-overflow: ellipsis;

    @media screen and (max-width: 576px) {
      font-size: var(--size-small);
    }
  }

  &:disabled {
    cursor: not-allowed;
    border: 2px solid var(--color-gray);
  }

  @media screen and (max-width: 576px) {
    padding: 8px 10px;
    font-size: var(--size-small);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-white);
    -webkit-box-shadow: 0 0 0px 40rem var(--color-black) inset;
  }
}

.error {
  border-color: var(--color-red) !important;
}

.phoneInput {
  padding: 0 10px;
  input {
    flex: 1;
    outline: none;
    border: none;
    padding: 5px 5px;
    display: flex;

    background: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--size-medium);
    font-weight: var(--width-regular);
    transition: all 0.2s ease-in-out;
    color: var(--color-white);
    margin: 0;

    &::placeholder {
      font-size: var(--size-medium);
      font-weight: var(--width-regular);
      color: var(--color-gray);
      text-overflow: ellipsis;

      @media screen and (max-width: 576px) {
        font-size: var(--size-small);
      }
    }

    &:disabled {
      cursor: not-allowed;
      border: 2px solid var(--color-gray);
    }

    @media screen and (max-width: 576px) {
      padding: 8px 5px;
      font-size: var(--size-small);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--color-white);
      -webkit-box-shadow: 0 0 0px 40rem var(--color-black) inset;
    }
  }
}
