.navigation {
  display: flex;
  flex-direction: column;

  margin: 0 0 20px 24px;

  @media (min-width: 992px) {
    margin: 0;
    flex-direction: row;
  }
}

.link {
  position: relative;

  margin: 20px 16px 0 0;

  text-decoration: none;
  font-size: var(--size-small);
  font-weight: var(--width-bold);
  text-transform: uppercase;

  @media (min-width: 992px) {
    margin: 0 16px;
    font-size: var(--size-normal);
  }

  @media (min-width: 1200px) {
    font-size: var(--size-medium);
  }

  :hover {
    color: var(--color-white);
  }

  ::before {
    content: '.';

    position: absolute;
    top: -6px;
    left: -8px;

    font-size: var(--size-normal);

    border-radius: 50%;

    @media (min-width: 992px) {
      display: none;
    }
  }
}
