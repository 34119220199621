.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 60%;
  margin: 20px 15px;
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 15px 0;
  }
}

.divider {
  border-right: 3px solid var(--color-white);

  &:last-child {
    border-right: none;
  }

  @media screen and (max-width: 768px) {
    border-width: 2px;
  }
}

.text {
  line-height: 1;
  padding: 0 5px;
}
