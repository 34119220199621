.wrapper {
  all: unset;
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 48%;
  }
}

.notAvailable {
  filter: grayscale(100%) brightness(0.5);
  pointer-events: none;
}

.imageWrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 74%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 6px;
  transition: all 0.15s ease-in-out;

  div {
    transition: all 0.3s ease-in-out;
    left: -100%;
  }

  .image {
    width: 100%;
    aspect-ratio: 0.74;
    object-fit: cover;
    object-position: top;
  }

  .cart {
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
    display: none;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
      right: 10px;
    }
  }

  &:hover {
    cursor: pointer;
    box-sizing: border-box;
    outline: 3px solid var(--color-white);

    .image {
      transition: all 0.15s ease-in-out;
      filter: brightness(0.2);
    }

    .cart {
      display: block;
    }

    div {
      left: 0;
    }
  }
}

.info {
  bottom: -65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.badge {
  z-index: 3;
  position: absolute;
  top: 20px;
  background-color: var(--color-dark-red);
  padding: 5px 10px;
  box-shadow: 0 0 5px var(--color-black);

  @media screen and (max-width: 768px) {
    top: 20px;
    padding: 0 5px;
  }
}

.blackBadge {
  background-color: var(--color-black);
}
