:root {
  --color-gray: #8e8e8e;
  --color-dark-gray: #666666;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-yellow: #feda1d;
  --color-red: #ff1111;
  --color-dark-red: #c11313;
  --color-light-gray-alt: #d9d9d9;
  --color-mobile-menu: #191919;
  --color-button-hover: #2f2f2f;

  --width-bold: 900;
  --width-medium: 600;
  --width-regular: 400;

  --size-small: 12px;
  --size-normal: 16px;
  --size-text: 20px;
  --size-medium: 24px;
  --size-big: 28px;
  --size-xBig: 36px;
  --size-large: 40px;
  --size-mLarge: 72px;
  --size-xLarge: 100px;
}
