.wrapper {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
}

.imageSection {
  margin-right: 20px;
  width: 60%;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin: 0;
  }
}

.imageWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 3fr 2.5fr;
  grid-gap: 10px;
}

.image {
  border-radius: 10px;
  object-fit: contain;
  width: 100%;
  height: auto;

  &:first-child {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;

  @media screen and (max-width: 1200px) {
    width: 100%;
    gap: 10px;
    margin-bottom: 16px;
  }
}

.link {
  text-decoration-color: var(--color-gray);
}
