.wrapper {
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5%;
  row-gap: 20px;

  @media screen and (max-width: 768px) {
    margin: 20px 0;
    column-gap: 4%;
  }
}

.fullscreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}
