.container {
  width: 100%;
  background-image: url('/assets/images/bg-industrial.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 40px 0;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    padding: 20px 0;
    margin: 20px 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textSection {
  width: 70%;

  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 768px) {
    gap: 18px;
  }

  @media screen and (max-width: 576px) {
    gap: 12px;
  }

  p {
    @media screen and (max-width: 474px) {
      font-size: var(--size-small);
    }
  }

  .title {
    @media screen and (max-width: 768px) {
      font-size: var(--size-big);
    }

    @media screen and (max-width: 474px) {
      font-size: var(--size-normal);
    }
  }
}

.imageSection {
  width: 30%;
}

.image {
  width: 100%;
  height: 100%;
  max-width: 360px;
  max-height: 360px;
  object-fit: contain;

  border-radius: 10px;
  cursor: pointer;
}

.loaderWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
