.button {
  cursor: pointer;

  width: 100%;
  padding: 11px 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-style: normal;
  text-transform: uppercase;
  font-size: var(--size-normal);
  font-weight: var(--width-bold);

  border: 2px solid var(--color-white);
  transition: all 0.15s ease-in-out;

  @media (min-width: 576px) {
    font-size: var(--size-medium);
  }
}

.filled {
  background-color: var(--color-white);
  color: var(--color-black);

  img {
    transition: all 0.15s ease-in-out;
  }

  &:hover {
    background-color: var(--color-button-hover);
    border-color: var(--color-button-hover);

    color: var(--color-white);

    img {
      filter: invert(1);
    }
  }
}

.bordered {
  background-color: var(--color-black);
  color: var(--color-white);

  &:hover {
    border-color: var(--color-button-hover);
  }
}

.addon {
  overflow: hidden;

  width: 20px;
  height: 20px;
  margin-left: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 576px) {
    width: 30px;
    height: 30px;
  }
}
