.wrapper {
  user-select: none;
  width: 100%;
  padding: 0 200px;
  position: relative;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;

  @media screen and (max-width: 992px) {
    padding: 0 100px;
    margin-bottom: 60px;

    .line {
      left: 100px;
      right: 100px;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 0 18px;
    margin-bottom: 50px;

    .line {
      left: 18px;
      right: 18px;
    }
  }
}

.itemWrapper {
  height: fit-content;
  position: relative;
  padding: 8px;
  background-color: var(--color-black);
}

.item {
  width: 74px;
  aspect-ratio: 1/1;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid var(--color-dark-gray);
  background-color: var(--color-black);

  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 52px;
  }

  p {
    color: var(--color-dark-gray);
    font-size: var(--size-large);
    font-weight: var(--width-bold);

    @media screen and (max-width: 768px) {
      font-size: var(--size-text);
    }
  }
}

.active {
  border: none;
  background-color: var(--color-white);

  p {
    color: var(--color-black);
  }
}

.text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  color: var(--color-dark-gray);
  font-size: var(--size-text);
  font-weight: var(--width-medium);

  @media screen and (max-width: 768px) {
    font-size: var(--size-normal);
  }
}

.textActive {
  color: var(--color-white);
}

.line {
  position: absolute;
  top: 45%;
  z-index: -1;

  left: 200px;
  right: 200px;
  height: 4px;
  background-color: var(--color-dark-gray);
}
