.wrapper {
  width: 100%;
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
}

.textWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  p {
    text-transform: unset;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.sizesWrap {
  width: 100%;
  display: flex;
  gap: 8px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
