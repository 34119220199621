.wrapper {
  display: flex;

  border: 2px solid var(--color-white);
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
}

.imageWrapper {
  width: 30%;
  position: relative;
  margin-right: 20px;

  @media screen and (max-width: 992px) {
    margin-right: 16px;
  }
}

.image {
  width: 100%;
  aspect-ratio: 0.74;
  object-fit: cover;
  object-position: top;
  margin-right: 20px;
  border-radius: 10px;

  @media screen and (max-width: 992px) {
    margin-right: 16px;
  }
}

.bigText {
  p {
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      margin-bottom: 6px;
    }
  }
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  min-height: 100px;

  p {
    color: var(--color-dark-gray);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 992px) {
      -webkit-line-clamp: 3;
    }

    @media screen and (max-width: 768px) {
      -webkit-line-clamp: 4;
    }

    @media screen and (max-width: 576px) {
      -webkit-line-clamp: 3;
    }
  }

  @media screen and (max-width: 576px) {
    min-height: 60px;
  }
}

.remove {
  width: 32px;
  height: 32px;

  @media screen and (max-width: 992px) {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
}
