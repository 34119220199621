.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
}

.paragraphWrapper {
  margin: 20px 0 40px 0;
}
