.wrapper {
  margin-top: 40px;
  height: 622px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media screen and (max-width: 992px) {
    height: 350px;
  }

  @media screen and (max-width: 576px) {
    height: 170px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 25%;
  &:first-child {
    flex: 1;
  }
}

.image {
  position: absolute;
  max-width: 622px;
  right: 0;
  object-fit: contain;

  @media screen and (max-width: 992px) {
    max-width: 322px;
  }

  @media screen and (max-width: 576px) {
    max-width: 170px;
  }
}

.title {
  font-size: 160px;

  text-transform: uppercase;
  font-weight: var(--width-bold);
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: var(--color-white);
  line-height: normal;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    font-size: var(--size-xLarge);
  }

  @media screen and (max-width: 768px) {
    font-size: var(--size-mLarge);
  }

  @media screen and (max-width: 576px) {
    font-size: var(--size-large);
  }
}

.subtitle {
  z-index: 1;
}
