.wrapper {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    gap: 6px;
  }

  img {
    &:nth-child(even) {
      margin-top: 55px;
    }
    width: 24%;

    @media screen and (max-width: 768px) {
      &:nth-child(even) {
        margin-top: 15px;
      }
    }
  }
}
