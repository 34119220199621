.wrapper {
  position: relative;
  width: 100px;
  height: 100px;
}

.image {
  width: 80px;
  height: 90px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:first-child {
    z-index: 2;
  }

  &:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 0;
    filter: sepia(93%) saturate(7464%) hue-rotate(291deg) brightness(101%) contrast(109%);
    animation: glitch-medium 0.45s step-end both infinite;
  }

  &:last-child {
    top: 0;
    left: 0;
    z-index: 1;
    filter: sepia(61%) saturate(5176%) hue-rotate(163deg) brightness(101%) contrast(98%);
    animation: glitch-small 0.45s step-end both infinite;
  }
}

@keyframes glitch-small {
  0% {
    top: 0;
    left: 0;
  }
  33% {
    top: 8px;
    left: -14px;
  }
  66% {
    top: -6px;
    left: 8px;
  }
  to {
    top: 0;
    left: 0;
  }
}

@keyframes glitch-medium {
  0% {
    top: 0;
    left: 0;
  }
  33% {
    top: -10px;
    left: 4px;
  }
  22% {
    top: -6px;
    left: -4px;
  }
  66% {
    top: 16px;
    left: 3px;
  }
  to {
    top: 0;
    left: 0;
  }
}
