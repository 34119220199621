.sizeBlock {
  cursor: pointer;
  border: 1px solid var(--color-light-gray-alt);
  height: 60px;
  min-width: 39px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: all 0.15s ease-in-out;
  position: relative;

  p {
    font-size: var(--size-medium);
    line-height: 1;
    color: var(--color-light-gray-alt);
    font-weight: var(--width-regular);
    text-transform: uppercase;
  }

  &:hover {
    background-color: var(--color-light-gray-alt);

    p {
      color: var(--color-black);
    }
  }

  @media screen and (max-width: 1200px) {
    min-width: 29px;
    padding: 0 10px;
    height: 45px;
  }
}

.sizeNotAvailable {
  filter: brightness(0.5);
  pointer-events: none;
}

.sizeSelected {
  background-color: var(--color-light-gray-alt);

  p {
    color: var(--color-black);
  }
}
