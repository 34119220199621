.circle {
  all: unset;

  width: 32px;
  aspect-ratio: 1;
  padding: 4px;

  border-radius: 50%;
  border: 2px solid var(--color-white);
  box-sizing: border-box;

  transition: background 0.25s ease-in-out, border 0.2s ease-in-out;
  background: radial-gradient(farthest-side, var(--color-white) 90%, #0000) 50%/0 0 no-repeat
    content-box;

  &:checked {
    background-color: var(--color-white);
    background-size: 100% 100%;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.error {
  border: 2px solid var(--color-red);
}

.label {
  display: inline-flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  margin: 5px 0;

  font-size: var(--size-medium);
  text-transform: uppercase;
  font-weight: var(--width-bold);
  line-height: 30px;
  font-style: normal;

  @media screen and (max-width: 768px) {
    font-size: var(--size-small);
  }
}

.disabled {
  color: var(--color-gray);

  input {
    border: 2px solid var(--color-gray);
  }

  pointer-events: none;
  cursor: not-allowed;
}
